import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";
import { ExportToExcel, ExportToPDF } from "../../Utilities/CommonMethods";
// import { ExportToExcel } from "../../Utilities/CommonMethods";

function EmployeeListing() {
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token = localStorage.getItem("token");
    const obj = {
      page: currentPage,
      limit: 5,
    };
    axios
      .post(`${BASE_URL}employee/getAllEmployees`, obj, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setEmployees(response.data.data);
          setPages(response.data.remainingCount);
          console.log("employees", response.data.data);
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteEmployee = (id) => {
    const token = localStorage.getItem("token");
    if (!window.confirm("Do you really want to delete this item?")) {
      return;
    }
    axios
      .delete(`${BASE_URL}employee/deleteEmployee/${id}`, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.message);
          setEmployees([]);
          getData();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getData();
  }, [currentPage]);
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">Employee Listing</h5>
              <div className="text-end ">
              <button className="btn btn-primary" onClick={()=>ExportToPDF(employees,"Employee")}>Export To PDF</button>
                {"  "}
          <button className="btn btn-primary" onClick={()=>ExportToExcel(employees,["_id","created_on","created_by_id","isDeleted","createdAt","__v"])}>Export To Excel</button>
              </div>
              <div className="table-responsive">
                <table className="table table-lightborder">
                  <thead>
                    <tr>
                      <th>Employee Id</th>
                      <th>Employee Type</th>

                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Joining Date</th>
                      <th>Salary</th>
                      <th>Commission</th>

                      <th>Status</th>
                      {/* <th>price</th> */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.length > 0 ? (
                      employees.map((item, index) => (
                        <tr key={index}>
                          <td>{item.employeeId}</td>
                          <td>{item.employee_type}</td>

                          <td>{item.name}</td>
                          <td>{item.mobile_no}</td>

                          <td>
                            {moment(item.joining_date).format("YYYY-MM-DD")}
                          </td>
                          <td>{item.salary}</td>
                          <td>{item.commission}</td>

                          <td>{item.status}</td>
                          <td>
                            <button className="border-0 bg-white">
                              <i
                                className="os-icon os-icon-pencil-2"
                                onClick={() =>
                                  navigate("/add-employee", { state: { item } })
                                }
                              />
                            </button>

                            <button
                              className="text-danger border-0 bg-white ps-2"
                              onClick={() => deleteEmployee(item._id)}
                            >
                              <i className="os-icon os-icon-database-remove" />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class={`page-item ${currentPage === 1 ? "disabled" : ""} `}>
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Previous"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>

                {Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                  <>
                    <li
                      key={page}
                      className={`page-item ${
                        page === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  </>
                ))}
                <li
                  class={`page-item ${currentPage === pages ? "disabled" : ""}`}
                >
                  <a
                    class="page-link"
                    href="#"
                    aria-label="Next"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EmployeeListing;
