import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
export function GetJSONHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'application/json',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };
    return header;
}
export function GetMultipartHederWithToken() {
    const token = localStorage.getItem("token");
    var header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "x-access-token": `${token}`,
            version: "1.0.0",
        }
    };

    return header;
}
const convertLoanData=async(flatArrayList)=>{
  const headers = ["Personal Info", "Vehical Details", "Loan Details"];
  const data = flatArrayList.map(row => {
    const personalInfo = `Name: ${row.name || ''}\nMobile: ${row.mobile_no || ''}\nAddress: ${row.address1 || ''}\nEmail: ${row.email || ''}\nRemark: ${row.remark || ''}`;
    const VehicalInfo = `Type: ${row.vehicle_type || ''}\nVehical No.: ${row.vehicle_no || ''}\nModel: ${row.model || ''}\ndistRto: ${row.distt_rto || ''}`;
    const LoanDetails = `Loan Type:${row.loan_type}\nLoan Req Amount: ${row.loan_request || ''}\nStatus: ${row.status || ''}`;
    return [personalInfo, VehicalInfo,LoanDetails]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}

const convertRTOData=async(flatArrayList)=>{
  const headers = ["Personal Info", "Vehical Details", "Work Fees"];
  const data = flatArrayList.map(row => {
    const personalInfo = `Name: ${row.name || ''}\nMobile: ${row.mobile_no || ''}\nAddress: ${row.address1 || ''}\nEmail: ${row.email || ''}\nRemark: ${row.remark || ''}`;
    const VehicalInfo = `Type: ${row.vehicle_type || ''}\nVehical Name.: ${row.vehicle_name || ''}\nVehical No.: ${row.vehicle_no || ''}\nModel: ${row.model || ''}\ndistRto: ${row.distt_rto || ''}`;
    const charges = `RTO Work Fees: ${row.work_fee || ''}`;
    return [personalInfo, VehicalInfo,charges]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}

const convertInsuranceData=async(flatArrayList)=>{
  const headers = ["Personal Info", "Vehical Details", "Work Fees"];
  const data = flatArrayList.map(row => {
    const personalInfo = `Name: ${row.name || ''}\nMobile: ${row.mobile_no || ''}\nAddress: ${row.address1 || ''}\nEmail: ${row.email || ''}\nRemark: ${row.remark || ''}`;
    const VehicalInfo = `Type: ${row.vehicle_type || ''}\nVehical Name.: ${row.vehicle_name || ''}\nVehical No.: ${row.vehicle_no || ''}\nModel: ${row.model || ''}\ndistRto: ${row.distt_rto || ''}`;
    const PolicyDetails = `Policy Type: ${row.policyType || ''}\nPolicy No: ${row.policyNo || ''}\nIDV: ${row.IDV || ''}\nPolicy Amount: ${row.policyAmount || ''}\nPolicy Issue Date: ${row.policyIssueDate || ''}\nPolicy Exp Date: ${row.policyExpDate || ''}`;
    return [personalInfo, VehicalInfo,PolicyDetails]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}

const convertCompanyData=async(flatArrayList)=>{
  const headers = ["Name", "Coordinator", "Contact Person","Location"];
  const data = flatArrayList.map(row => {
    const personalInfo = `Company Type: ${row.company_type || ''}\nName: ${row.companyName || ''}\nEmail: ${row.email || ''}`;
    const coordinator = `Name: ${row.company_coordinator_name || ''}\nMobile.: ${row.company_coordinator_mobile_no || ''}`;
    const contactPerson = `Name: ${row.company_contact_person_name || ''}\nMobile: ${row.company_contact_person_mobile_no || ''}`;
    const location = `Branch Name: ${row.branch_name || ''}\nLocation: ${row.company_location || ''}`;
    return [personalInfo, coordinator,contactPerson,location]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}

const convertEmployeeData=async(flatArrayList)=>{
  const headers = ["Basic Info", "Salary Info","Address","Bank Details"];
  const data = flatArrayList.map(row => {
    const personalInfo = `ID: ${row.employeeId || ''}\nType: ${row.employee_type || ''}\nName: ${row.name || ''}\nMobile: ${row.mobile_no || ''}`;
    const salaryInfo = `salary/commission: ${row.salary || row.commission}\nJoining Date.: ${row.joining_date || ''}`;
    const contactPerson = `Name: ${row.company_contact_person_name || ''}\nMobile: ${row.company_contact_person_mobile_no || ''}`;
    const location = `State: ${row.state || ''}\nDisctrict: ${row.district || ''}\nTehsil:${row.tehsil || ''}\nAddress: ${row.address || ''}`;
    const bankDetails = `Account Holder Name: ${row.account_holder_name || ''}\nBank name: ${row.bank_name || ''}\nBranch name:${row.branch_name || ''}\nAccount number: ${row.account_number || ''}\nIfsc code: ${row.ifsc_code}`;
    return [personalInfo, salaryInfo,location,bankDetails]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}

const convertLadgerData=async(flatArrayList)=>{
  console.log(flatArrayList)
  const headers = [ "Date","Description","Debit","Credit","Balance"];
  const data = flatArrayList?.map(row => {
    const Date = ` ${moment(row.date).format("DD MMM YYYY") || ''}`;
    const desc = ` ${row.description || ''}`;

    const debit = `${row.debit || 0} RS/-`;
    const credit = `${row.credit || 0} RS/- `;
    const balance = `${row.balance || 0} RS/-`;


    return [Date,desc, debit,credit,balance]; 
  });
  let result={
    headers: headers,
    data: data,
  }
  return result
  
}
export const ExportToPDF = async(arrayList, pdffor,loanData) => {
  let startY 
  console.log(111,arrayList,pdffor)

  if (!arrayList || arrayList.length === 0) {
    return; 
  }

  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = parent ? `${parent}_${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  };
  const doc = new jsPDF();

  const flatArrayList = arrayList.map(item => flattenObject(item));
   
  if(pdffor=="Loan"){
    doc.setFont('helvetica', 'bold');
    doc.text('Loan List', 14, 15);
    var {headers,data}=await convertLoanData(flatArrayList);
  }else if(pdffor=="RTO"){
    doc.setFont('helvetica', 'bold');
    doc.text('RTO List', 14, 15);
    var {headers,data}=await convertRTOData(flatArrayList);
  }else if(pdffor=="Insurance"){
    doc.setFont('helvetica', 'bold');
    doc.text('Insurance List', 14, 15);
    var {headers,data}=await convertInsuranceData(flatArrayList);
  }else if(pdffor=="Company"){
    doc.setFont('helvetica', 'bold');
    doc.text('Company List', 14, 15);
    var {headers,data}=await convertCompanyData(flatArrayList);
  }else if(pdffor=="Employee"){
       doc.setFont('helvetica', 'bold');
   doc.text('Employee List', 14, 15);
    doc.setFontSize(8);
    var {headers,data}=await convertEmployeeData(flatArrayList);

  }else if(pdffor=="ladger"){
    var {headers,data}=await convertLadgerData(arrayList);
   // Ensure loanData is valid
   const applicantName = loanData?.name || "N/A";
   const mobileNo = loanData?.mobile_no || "N/A";
   const loanRequest = loanData?.loan_request || "N/A";
   const paymentReceived = loanData?.payment || "N/A";
   doc.setFontSize(10);
   // Applicant Name and Loan Request (aligned horizontally, closer)
   
  //  doc.setFont('helvetica', 'bold');
  //  doc.text('Applicant Detail', 14, 15);

   doc.setFont('helvetica', 'bold');
   doc.text('Applicant Name :', 14, 20);
   doc.setFont('helvetica', 'normal');
   doc.text(applicantName, 45, 20);  // Adjusted closer
   
   doc.setFont('helvetica', 'bold');
   doc.text('Loan Request :', 75, 20);  // Adjusted closer
   doc.setFont('helvetica', 'normal');
   doc.text(`${loanRequest}`, 105, 20);  // Adjusted closer
   
   // Mobile Number and Payment Received (aligned horizontally, closer)
   doc.setFont('helvetica', 'bold');
   doc.text('Mobile Number :', 14, 25);
   doc.setFont('helvetica', 'normal');
   doc.text(mobileNo, 45, 25);  // Adjusted closer
   
   doc.setFont('helvetica', 'bold');
   doc.text('Payment Received :', 75, 25);  // Adjusted closer
   doc.setFont('helvetica', 'normal');
   doc.text(`${paymentReceived}`, 110, 25);  // Adjusted closer
    startY = 30; // Adjust startY to avoid text overlap
  }
  doc.autoTable({
    head: [headers],
    body: data,
    startY: startY||20,
    margin: { top: 50 },
    didDrawPage: (data) => {
      doc.text(`Page ${data.pageCount}`, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10);
    },
  });

  doc.save('data.pdf');
};





export const ExportToExcel = (arrayList, excludeFields = []) => {
  if (!arrayList || arrayList.length === 0) {
    return; // No data to export
  }

  // Helper function to flatten objects
  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = parent ? `${parent}_${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  };

  // Flatten all objects in the array
  const flatArrayList = arrayList.map(item => flattenObject(item));

  // Remove excluded fields
  const filteredArrayList = flatArrayList.map(row => {
    const filteredRow = { ...row };
    excludeFields.forEach(field => {
      delete filteredRow[field];
    });
    return filteredRow;
  });

  // Create the HTML table structure as a string
  let table = `<table border="1"><tr>`;

  // Get the keys (column headers) from the first object
  const headers = Object.keys(filteredArrayList[0]);
  headers.forEach(header => {
    table += `<th>${header}</th>`;
  });
  table += `</tr>`;

  // Add the data rows to the table
  filteredArrayList.forEach((row) => {
    table += `<tr>`;
    headers.forEach(header => {
      const cellValue = row[header] !== undefined ? row[header] : ''; // Handle undefined values
      table += `<td>${cellValue}</td>`;
    });
    table += `</tr>`;
  });

  table += `</table>`;

  // Create a Blob with the correct MIME type for Excel
  const excelBlob = new Blob([table], {
    type: "application/vnd.ms-excel"
  });

  // Create a downloadable link for the Blob
  const link = document.createElement("a");
  const url = URL.createObjectURL(excelBlob);
  link.href = url;
  link.download = "data.xls"; // File name with .xls extension

  // Append the link to the document body and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the link after download
  document.body.removeChild(link);
};

  
  
  
  