import React, { useState } from 'react'

function VehicalDetail({
   SaveRTO,
    values,
    handleInputChange,
    setVehicalType,
    vehicalType
}) {
  let vehicalArr=["TRACTOR","CAR","LOADING","TRUCK","DUMPER","HARVESTER","JCB","Other"]
  if(values.vehicle_type&&!vehicalArr.includes(values.vehicle_type)){
   setVehicalType(values.vehicle_type);
   console.log("in", values.vehicle_type)
   values.vehicle_type="Other"

  }

  // let vehicalType=values.vehicle_type;

  return (
    <fieldset>
    <legend>
      <span>Vahical Details </span>
    </legend>
    <div className="row">
      {
        values.vehicle_type!="Other"&&
        <div className="col-sm-6">
        <div className="form-group">
          <label> Vehicle Type</label>
          <select
            className="form-control "
            onChange={(e) => handleInputChange(e)}
            name="vehicle_type"
            value={values.vehicle_type}
          >
            <option value="">--select--</option>
            {
              vehicalArr.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))
            }
          </select>
        </div>
      </div>
      }
   
     {
      values.vehicle_type=="Other"&&
      <div className="col-sm-6">
      <div className="form-group">
        <label htmlFor="">Vehicle Type</label>
        <input
          className="form-control"
          placeholder="Enter vehicle type"
          type="text"
          onChange={(e) => setVehicalType(e.target.value)}
          name="vehicalType"
          value={vehicalType}
        />
      </div>
    </div>

     }
    
      <div className="col-sm-6">
        <div className="form-group">
          <label htmlFor="">Vehicle Name</label>
          <input
            className="form-control"
            placeholder="Enter vehicle name"
            type="text"
            onChange={(e) => handleInputChange(e)}
            name="vehicle_name"
            value={values.vehicle_name}
          />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <label for="">Vehicle No/ RC Card</label>
          <input
            class="form-control"
            placeholder="Enter Vehicle No."
            type="text"
            onChange={(e) => handleInputChange(e)}
            name="vehicle_no"
            value={values.vehicle_no}
          />
        </div>
      </div>

      <div className="col-sm-6">
        <div className="form-group">
          <label htmlFor="">Model</label>
          <input
            className="form-control"
            placeholder="Enter Model"
            type="text"
            onChange={(e) => handleInputChange(e)}
            name="model"
            value={values.model}
          />
        </div>
      </div>

      <div className="col-sm-6">
        <div className="form-group">
          <label htmlFor="">Distt RTO</label>
          <input
            className="form-control"
            placeholder="Enter Model"
            type="text"
            onChange={(e) => handleInputChange(e)}
            name="distt_rto"
            value={values.distt_rto}
          />
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          <label htmlFor="">RTO Work Fee</label>
          <input
            className="form-control"
            placeholder="Enter work fee"
            type="number"
            onChange={(e) => handleInputChange(e)}
            name="work_fee"
            value={values.work_fee}
          />
        </div>
      </div>

      <div className="col-sm-6 mt-4">
        <div className="row">
          <div className="col">
            <div className="form-group text-center">
              <button
                className={`btn btn-primary p-2 w-100 ${
                  values.work_fee ? "" : "disabled"
                }`}
                onClick={() => SaveRTO("rtoPayment")}
              >
                Pay Work Fees
              </button>
            </div>
          </div>
          <div className="col">
            <div className=" text-right">
              <button
                className="btn btn-primary p-2 text-white"
                onClick={() => SaveRTO("secondStep")}
                // onClick={}
              >
                Save & Continue
              </button>
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  )
}

export default VehicalDetail