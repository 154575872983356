import React, { useEffect } from "react";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ExportToExcel, ExportToPDF } from "../Utilities/CommonMethods";

const LedgerTable = () => {
  const { state } = useLocation();
  const tableStyle = {
    marginTop: "35px",
    width: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
  };

  const thStyle = {
    backgroundColor: "#f8f9fa",
    padding: "10px",
    border: "1px solid #dee2e6",
  };

  const tdStyle = {
    padding: "10px",
    border: "1px solid #dee2e6",
  };

  const data = state.records;
  useEffect(() => {
    console.log(state);
  }, [state]);
  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="row">
                <div className="col">
                  <h5 className="form-header fw-bold fs-4">Ledger</h5>
                </div>
                <div className="col">
                  <div className="text-end ">
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        ExportToPDF(state.records, "ladger", state)
                      }
                    >
                      Export To PDF
                    </button>
                    {"  "}
                 <button className="btn btn-primary" onClick={()=>ExportToExcel(state.records,["_id"])}>Export To Excel</button>
                  </div>
                </div>
              </div>

              <div className="d-flex gap-4">
                <div>
                  <span>
                    Applicant Name :{" "}
                    <span className="fw-semibold  text-capitalize">
                      {state.name}
                    </span>{" "}
                  </span>
                  <br />
                  <span>
                    Mobile Number :{" "}
                    <span className="fw-semibold ">{state.mobile_no}</span>
                  </span>
                </div>
                <div>
                  <span>
                    Loan Request :{" "}
                    <span className="fw-semibold  text-capitalize">
                      {state.loan_request}
                    </span>{" "}
                  </span>
                  <br />
                  <span>
                    Payment Recieved :{" "}
                    <span className="fw-semibold ">{state.payment}</span>
                  </span>
                </div>
              </div>

              <table style={tableStyle} className="table table-bordered">
                <thead>
                  <tr>
                    <th style={thStyle}>Date</th>
                    <th style={thStyle}>Description</th>
                    <th style={thStyle}>Debit</th>
                    <th style={thStyle}>Credit</th>
                    <th style={thStyle}>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data.map((row, index) => (
                      <tr key={index}>
                        <td style={tdStyle}>
                          {moment(row.date).format("DD MMM YYYY")}
                        </td>
                        <td style={tdStyle}>{row.description}</td>
                        <td style={tdStyle}>
                          <i className="fa fa-inr"></i> {row.debit}
                        </td>
                        <td style={tdStyle}>
                          <i className="fa fa-inr"></i> {row.credit}
                        </td>
                        <td style={tdStyle}>
                          <i className="fa fa-inr"></i> {row.balance}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LedgerTable;
