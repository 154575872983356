import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import moment from "moment";
import { ExportToExcel, ExportToPDF } from "../../Utilities/CommonMethods";

function RTOListing() {
  const [rtos , setRtos]=useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const navigate= useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages]=useState(1)
const BASE_URL= process.env.REACT_APP_BASE_URL;
  const getData = () => {
    const token= localStorage.getItem('token');
    const obj = {
      page: currentPage,
      limit: 8,
    };
    axios
      .post(`${BASE_URL}admin/getAllRTO`, obj, {
        headers: {
          'x-access-token':`${token}`,
          "Content-Type": "Application/json",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRtos(response.data.data);
          setPages(response.data.remainingCount);
          console.log("employees",response.data.data);
        }else{
          if(response.data.error_code==461){
            navigate("/login")
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const deleteRTO=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteRTO/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        setRtos([])
        getData();
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }

  const searchRtos = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}admin/searchRTO`, {
        params: {
          search: searchTerm, // Search by name, mobile number, or vehicle number
          status: status      // Optional: Filter by status
        },
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          version: "1.0.0"
        }
      });
  
      if (response.data.success) {
        setRtos(response.data.data); // Handle the response
      } else {
        setRtos([]);
      }
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };

  useEffect(()=>{
    if(searchTerm!=""||status!=""){
      searchRtos()
    }else{
      getData();
    }
  },[searchTerm, status])

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(()=>{
    getData();
  },[currentPage])
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
            <h5 className="form-header">RTO Listing</h5>
            <div className="text-end ">
          <button className="btn btn-primary" onClick={()=>ExportToPDF(rtos,"RTO")}>Export To PDF</button>
          {"  "}
          <button className="btn btn-primary" onClick={()=>ExportToExcel(rtos,["recieptId","_id","created_on","created_by_id","isDeleted","createdAt","__v"])}>Export To Excel</button>
          </div>
            <div className="row d-flex justify-content-between my-4">
              <div className="col">
            
              <input
          type="text"
          placeholder="Search by name, mobile, or vehicle number"
          className="searchLoan"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
              </div>
              <div className="col-3 text-end">
              <select className="form-control searchSelect" name="status" value={status} onChange={(e)=>setStatus(e.target.value)}
                style={{ height:"45px", borderRadius:"0px" }}>
              <option value="" selected="true">Select RTO Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Basic Details</th>
                    <th>Vehicle Details</th>
                    <th>RTO Work Fees</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Modified By</th>


                    {/* <th>price</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rtos.length>0?rtos.map((item,index)=>(
                      <tr key={index}>
                   
                      <td>
                        <div>
                        {item.name}<br/>
                        {item.email}<br/>
                        {item.mobile_no}<br/>
                        {item.address}

                        </div>
                      </td>
                      <td>
                        <div>
                         Type: {item.vehicle_type}<br/>
                       V. No: {item.vehicle_no}<br/>
                       M. No: {item.model}<br/>
                      Dist: {item.distt_rto}

                        </div>
                      </td>
  
                      
             <td>
              <i className="fa fa-inr"></i>{item.work_fee?item.work_fee:0}  &nbsp;&nbsp;
              <button
                className={`btn btn-primary p-2 `}
                onClick={() =>   navigate("/rto-agent-payment", { state: item})}
              >
                Pay Work Fees
              </button>

             </td>
  
                      <td>{item.status}</td>
                      <td>
                        
                        {item?.created?.by?.name}<br/>
                        {moment(item.created?.on).format("DD MMM YYYY")}
                        </td>
                        <td>
                        
                        {item?.modified?.by?.name}<br/>
                       { item?.modified?.by?.name&&moment(item.modified?.on).format("DD MMM YYYY")}
                        </td>
                      <td>
                        <button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/add-rto", { state: { "item":item,length:rtos.length } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteRTO(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                    )):<div></div>
                  }
               
                </tbody>
              </table>
            </div>
          </div>
          <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li
                    class={`page-item ${currentPage === 1 ? "disabled" : ""} `}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>

                  {Array.from({ length: pages }, (_, i) => i + 1).map(
                    (page) => (
                      <>
                        <li
                          key={page}
                          className={`page-item ${
                            page === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </button>
                        </li>
                      </>
                    )
                  )}
                  <li
                    class={`page-item ${
                      currentPage === pages ? "disabled" : ""
                    }`}
                  >
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Next"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default RTOListing;
