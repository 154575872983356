import React, { useEffect, useState } from "react";
import Mobilemenu from "../components/Mobilemenu";
import Desktopmenu from "../components/Desktopmenu";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dashboardimg from "../img/dashboard.jpg";
import MenuTop from "../components/MenuTop";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  useEffect(() => {
    console.log("user", role);
  }, []);
  return (
    <Layout>
      <div className="element-wrapper">
        <div className="element-box p-0">
          <div className="content-i">
            <div className="content-box p-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="element-wrapper pb-0">
                    <div
                      className="element-content"
                      style={{
                        backgroundImage: `url(${dashboardimg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width:"100%",height:"100vh"
                      }}
                    >
                      {/* <div className="d-none" >
                        <h1
                          className="text-white"
                          style={{ fontFamily: "monospace",fontSize: "60px", fontWeight:"800"}}
                        >
                          Welcome to Royal Financial Services
                        </h1>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
