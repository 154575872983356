import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Layout from "../../../components/Layout";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import "../../../styles/receipt.css"; // Assuming custom CSS for detailed styles

function Receipt() {
  const receiptContent = React.createRef();
  const { state } = useLocation();
  const { profile } = useSelector((state) => state.profile);

  const [values, setValues] = useState(profile);
  const [image, setImage] = useState(profile.logo);

  const handlePrintReceipt = useReactToPrint({
    content: () => receiptContent.current,
    documentTitle: "Receipt",
    style: `
      @media print {
        body {
          font-family: Arial, sans-serif;
          color: black !important;
        }
        .print-content {
          background-color: white !important;
          padding: 20px;
          border: none;
          width: 100%;
        }
        .table th, .table td {
          border: 1px solid #000 !important;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid #000;
          padding-bottom: 10px;
          margin-bottom: 20px;
        }
        .company-info h2 {
          font-size: 1.5rem;
          margin: 0;
        }
        .table th {
          background-color: #f5f5f5;
          text-align: center;
        }
      }
    `,
  });

  useEffect(() => {
    handlePrintReceipt();
  }, []);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="p-4 print-content" ref={receiptContent}>

              <div className="text-center">
                  <h2>{values?.companyName || "ROYAL FINANCIAL SERVICES"}</h2>
                  <p>{values?.address || "KUNJ PLAZA, 2nd FLOOR, JNASHI ROAD, DABRA, DIST-GWL"}</p>
                  <p>{`MOB: ${values?.mobile_no || "_________"}`}</p>
                  <h4>PAYMENT SLIP</h4>
                </div>
                {/* <div className="row mt-3">
                  <div className="col">
                    <p>EMPLOYEE ID: <b>{state?.item?.employee[0]?.employeeId || "__________"}</b></p>
                    <p>DESIGNATION: <b>{state?.item?.employee[0]?.designation || "_________"}</b></p>
                    <p>BANK NAME: <b>{state?.item?.employee[0]?.bank_name || "_________"}</b></p>
                  </div>
                  <div className="col text-end">
                    <p>EMPLOYEE NAME: <b>{state?.item?.employee[0]?.name || "_________"}</b></p>
                    <p>MONTH & YEAR: <b>{`${state?.item?.salary_month || "________"} ${state?.item?.salary_year || "________"}`}</b></p>
                    <p>BRANCH: <b>{state?.item?.employee[0]?.branch || "________"}</b></p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col">
                    <h4>RECEIPT TO:</h4>
                    <br/>
                    <p>NAME: {state?.rtoDetail?.name || "__________"}</p>
                    <p>PHONE NUMBER: {state?.rtoDetail?.mobile_no || "___________"}</p>
                    <p>ADDRESS: {state?.rtoDetail?.address || "__________"}</p>
                  </div>
                  <div className="col text-end">
                    <h4>RECEIPT DETAILS:</h4>
                    <br/>
                    <p>RECEIPT ID: <strong>{state?.item?.receiptNumber || "________"}</strong></p>
                    <p>DATE: <strong>{new Date().toLocaleDateString()}</strong></p>
                  </div>
                </div>

                <table className="table table-bordered mt-4">
                  <thead>
                    <tr>
                      <th>DESCRIPTION</th>
                      <th>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>RTO WORK CHARGES</td>
                      <td>{state?.rtoDetail?.work_fee || "0"}  Rs/-</td>
                    </tr>
                    <tr>
                      <td className="text-end"><strong>RECEIVED AMOUNT</strong></td>
                      <td><strong>{state?.item?.amount || "0"}  Rs/-</strong></td>
                    </tr>
                    <tr>
                      <td className="text-end"><strong>DUE AMOUNT</strong></td>
                      <td><strong>{state?.item?.remaining_amount || "0"}  Rs/-</strong></td>
                    </tr>
                  </tbody>
                </table>

                {/* <div className="footer text-center mt-4">
                  <p>Thank you for your business!</p>
                  <p>Generated by {values?.companyName || "_________"}</p>
                </div> */}
              </div>
              <button className="btn btn-primary mt-3" onClick={handlePrintReceipt}>Print Receipt</button>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          @media print {
            .print-content {
              background-color: white;
              padding: 40px;
              height: 100vh;
            }
          }
        `}
      </style>
    </Layout>
  );
}

export default Receipt;
