import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import LoanStatusListing from "../../components/Loan/LoanStatusList";



function PendingLoanListing() {
 
  return (
<LoanStatusListing status="Pending Leed"/>
  );
}

export default PendingLoanListing;
