import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";



function LoanStatusListing({status}) {
  const [loans , setloans]=useState([]);
  const navigate= useNavigate();
  const BASE_URL= process.env.REACT_APP_BASE_URL;

  const deleteLoan=(id)=>{
    const token= localStorage.getItem('token');
    if (!(window.confirm("Do you really want to delete this item?"))) {
      return;
    } 
    axios.delete(`${BASE_URL}admin/deleteLoan/${id}`,{
      headers:{
        "x-access-token":`${token}`,
        "Content-Type":"Application/json",
        version:"1.0.0"
      }
    }).then((response)=>{
      if(response.data.success){
        toast.error(response.data.message);
        setloans([])
      }
    }).catch((err)=>{
      console.log(err.message);
    })
  }


  const searchLoans = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}admin/searchloan`, {
        params: {
          status: status      // Optional: Filter by status
        },
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          version: "1.0.0"
        }
      });
  
      if (response.data.success) {
        setloans(response.data.data); // Handle the response
      } else {
        setloans([]);
      }
    } catch (error) {
      console.error('Error fetching loans:', error);
    }
  };
  useEffect(()=>{
    searchLoans();
  },[])
  return (
    <Layout>
    <div className="content-i">
      <div className="content-box">
        <div className="element-wrapper">
          <div className="element-box">
            <div className="row d-flex justify-content-between">
              <div className="col">
              <h5 className="form-header text-bold">Loan Listing</h5>
              </div>
              <div className="col-3 text-end">
              <select className="form-control" name="selectedStatus" value={status} disabled >
              <option value="">Select Loan Status</option>
              <option value="Pending Leed">Pending Leed</option>
              <option value="Rejected Leed">Rejected Leed</option>
              <option value="Running Leed">Running Leed</option>
              <option value="Agree Leed">Agree Leed</option>
             </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lightborder">
                <thead>
                  <tr>
                    <th>Basic Detail</th>
                    <th>Vehical Detail</th>
                    
                    <th>Loan Request Amount</th>
                    <th>Loan Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                   loans.length>0?loans.map((item,index)=>(
                      <tr key={index}>
                   
                      <td>
                       <div>
                        {item.name}
                       <br/>
                        {item.mobile_no}
                        <br/>
                        {item.email}
                        <br/>
                        {item.address}
                        </div>
                        </td>
                      <td>
                      <div>
                     Type: {item.vehicle_type}<br/>
                     Vehical. No: {item.vehicle_no}<br/>
                     Model. No: {item.model}<br/>
                     Dist: {item.distt_rto}
                      </div>
                      </td>

                      <td>{item.loan_request}</td>
                      <td>{item.status}</td>
                      
                      <td>
                    
                        <button className="border-0 bg-white">
                          <i className="os-icon os-icon-pencil-2" 
                          onClick={() =>
                            navigate("/add-loan", { state: { item } })
                          }
                          />
                        </button>
  
                        <button  className="text-danger border-0 bg-white ps-2" onClick={()=>deleteLoan(item._id)} >
                          <i className="os-icon os-icon-database-remove" />
                        </button>
                      </td>
                    </tr>
                    )):
                    <div>
                    </div>
                  }
               
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

export default LoanStatusListing;
