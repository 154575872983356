import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const initialState = {
    old_pswd: "",
    new_pswd: "",
  };
function ChangePassword() {
  const { role } = useSelector((state) => state.auth);
  const [values, setValues] = useState(initialState);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate=useNavigate();
    
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
   
  const Submit = () => {
  const token= localStorage.getItem('token');
  let api;
  if (values.new_pswd !== values.conf_pswd) {
    toast.error("confim password and new password are not same ");
    return;
  }
  // if()
  const obj = {
    old_pass: values.old_pswd,
    new_pass: values.new_pswd,
    
  };
  
  axios
    .post(`${BASE_URL}auth/change-password`, obj, {
      headers: {
        "x-access-token": `${token}`,
        "Content-Type": "Application/json",
        version: "1.0.0",
      },
    })
    .then((response) => {
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        if(response.data.error_code===461){
            navigate("/login")
        }
        toast.error(response.data.message);
      }
    }).catch((err)=>{
        console.log(err.message);
    })
}; 
  return (
   <Layout>
     <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-lg-12">
              <div className="element-wrapper">
                <h6 className="element-header"> Change Password </h6>
                <div className="element-box">
                  <div>
                    {/* <h5 className="form-header"></h5> */}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Old Password:</label>
                          <input
                            className="form-control"
                            placeholder="Enter old password"
                            name="old_pswd"
                            onChange={(e) => handleInputChange(e)}
                            value={values.old_pswd}
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>New Password</label>
                          <input
                            className="form-control"
                            placeholder="Enter New Password"
                            name="new_pswd"
                            onChange={(e) => handleInputChange(e)}
                            value={values.new_pswd}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor>Confirm Password</label>
                          <input
                            className="form-control"
                            placeholder="Enter confirm password"
                            type="text"
                            name="conf_pswd"
                            onChange={(e) => handleInputChange(e)}
                            value={values.conf_pswd}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => Submit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </Layout>
  )
}

export default ChangePassword