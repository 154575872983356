import React, { useState } from "react";
import moment from "moment";

function VehicleDetail({
  handleInputChange,
  values,
  SaveLoan,
  loanId,
  loading,
  setActiveTab,
}) {
  const [loanTaken, setLoanTaken] = useState("No");
  const handleRadioChange = (event) => {
    setLoanTaken(event.target.value);
  };
  return (
    <>
      <fieldset>
        <legend>
          <span>Vehicle Information</span>
        </legend>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label> Segment/Class</label>
              <select
                className="form-control"
                onChange={(e) => handleInputChange(e)}
                name="vehicle_type"
                value={values.vehicle_type}
              >
                <option value="">--select--</option>
                <option value="TRACTOR">{"TRACTOR"}</option>
                <option value="CAR">{"CAR"}</option>

                <option value="TRUCK">{"TRUCK"}</option>
                <option value="DUMPER">{"DUMPER"}</option>
                <option value="LOADING VEHICAL">{"LOADING VEHICAL"}</option>
                <option value="HARVESTER">{"HARVESTER"}</option>
                <option value="JCB">{"JCB"}</option>
                <option value="PIKUP">{"PIKUP"}</option>
              </select>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Vehicle No/ RC Card</label>
              <input
                className="form-control"
                placeholder="Enter Vehicle No."
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="vehicle_no"
                value={values.vehicle_no}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Model</label>
              <input
                className="form-control"
                placeholder="Enter Model"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="model"
                value={values.model}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Maker Classification</label>
              <input
                className="form-control"
                placeholder="Enter Maker Classification"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="maker_classification"
                value={values.maker_classification}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Distt RTO</label>
              <input
                className="form-control"
                placeholder="Enter Distt RTO"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="distt_rto"
                value={values.distt_rto}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Vehicle Owner Name</label>
              <input
                className="form-control"
                placeholder="Enter Vehicle Owner Name"
                type="text"
                onChange={(e) => handleInputChange(e)}
                name="vehicle_owner"
                value={values.vehicle_owner}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Vehicle-Manufacture Date</label>
              <input
                className="form-control"
                placeholder="Enter Manufacturing Date"
                type="date"
                onChange={(e) => handleInputChange(e)}
                name="vehicle_manufacturing_date"
                value={moment(values.vehicle_manufacturing_date).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="">Vehicle-Registration Date</label>
              <input
                className="form-control"
                placeholder="Enter Registration Date"
                type="date"
                onChange={(e) => handleInputChange(e)}
                name="vehicle_registration_date"
                value={moment(values.vehicle_registration_date).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <label>Do you have Loan Previously on this vehicle?</label>
            <div className="row">
              <div className="col-2">
                <label htmlFor="loanPreviouslyYes">Yes</label>
                &nbsp;&nbsp;
                <input
                  type="radio"
                  id="loanPreviouslyYes"
                  name="loanPreviously"
                  value={"Yes"}
                  checked={loanTaken === "Yes"}
                  onChange={handleRadioChange}
                />
              </div>

              <div className="col-2">
                <label htmlFor="loanPreviouslyNo">No</label>
                &nbsp;&nbsp;
                <input
                  type="radio"
                  id="loanPreviouslyNo"
                  name="loanPreviously"
                  value={"No"}
                  checked={loanTaken === "No"}
                  onChange={handleRadioChange}
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      {loanTaken == "Yes" && (
        <fieldset>
          <legend>
            <span>Vehicle Finance Details</span>
          </legend>
          <div className="row">
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">Finance Company Name</label>
                <input
                  className="form-control"
                  placeholder="Enter Finance Company Name"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  name="finance_company_name"
                  value={values.finance_company_name}
                />
              </div>
            </div> */}

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">Track Record Of Finance</label>
                <input
                  className="form-control"
                  placeholder="Enter Track Record Of Finance"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  name="track_record_of_finance"
                  value={values.track_record_of_finance}
                />
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">SCE Name</label>
                <input
                  className="form-control"
                  placeholder="Enter SCE Name"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  name="sce_name"
                  value={values.sce_name}
                />
              </div>
            </div> */}

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">NOC Status</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="nocStatus"
                  value={values.nocStatus}
                >
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">Area</label>
                <input
                  className="form-control"
                  placeholder="Enter Area"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  name="area"
                  value={values.area}
                />
              </div>
            </div>

       

            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="">For Closer</label>
                <input
                  className="form-control"
                  placeholder="Enter For Closer"
                  type="number"
                  onChange={(e) => handleInputChange(e)}
                  name="forcloser"
                  value={values.forcloser}
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}

      <div className="form-buttons-w text-right">
        <span className=" text-right">
          <button
            className="btn btn-primary text-white"
            onClick={() => setActiveTab("firstStep")}
          >
            Back
          </button>
        </span>
        &nbsp;&nbsp;{" "}
        <span className=" text-right">
          <button
            className="btn btn-primary text-white"
            onClick={() => SaveLoan("thirdStep")}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>Save & Continue</span>
            )}
          </button>
        </span>
        &nbsp;&nbsp;
      </div>
    </>
  );
}

export default VehicleDetail;
