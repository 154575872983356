import axios from "axios";
import {
  GetJSONHederWithToken,
} from "../../Utilities/CommonMethods";

const BASE_URL = process.env.REACT_APP_BASE_URL
export const getEmployee= async()=>{
    try {
        let header = GetJSONHederWithToken();
        const data = await axios.post(`${BASE_URL}employee/getAllEmployees`,{},header);
        return data

    } catch (error) {
        console.log(error)
    }
}
export const getEmployeeId= async()=>{
    try {
        let header = GetJSONHederWithToken();
        const data = await axios.post(`${BASE_URL}employee/getemployeeid`,{},header);
        return data

    } catch (error) {
        console.log(error)
    }
}

