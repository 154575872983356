import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

function SalarySlip() {
  const receiptContent = React.createRef();
  const { state } = useLocation();
  const { profile } = useSelector((state) => state.profile);

  const [values, setValues] = useState(profile);
  const [image, setImage] = useState(profile.logo);

  const handlePrintReceipt = useReactToPrint({
    content: () => receiptContent.current,
    documentTitle: "Salary Slip",
    style: `
      @media print {
        body {
          background-color: white !important;
          color: black !important;
        }
        .print-content {
          background-color: white !important;
          color: black !important;
          border: none !important;
          width: 100% !important;
          padding: 30px;
        }
        .table th, .table td {
          border: 1px solid #000 !important;
        }
      }
    `,
  });

  useEffect(() => {
    console.log("State data:", state);
    handlePrintReceipt();
  }, []);

  return (
    <Layout>
      <div className="content-i">
        <div className="content-box">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="p-4 print-content" ref={receiptContent}>
                <div className="text-center">
                  <h2>{values?.companyName || "ROYAL FINANCIAL SERVICES"}</h2>
                  <p>{values?.address || "KUNJ PLAZA, 2nd FLOOR, JNASHI ROAD, DABRA, DIST-GWL"}</p>
                  <p>{`MOB: ${values?.mobile_no || "_________"}`}</p>
                  <h4>MONTHLY SALARY SLIP</h4>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <p>EMPLOYEE ID: <b>{state?.item?.employee[0]?.employeeId || "__________"}</b></p>
                    <p>DESIGNATION: <b className="text-uppercase">{state?.item?.employee[0]?.designation || "_________"}</b></p>
                    <p>BANK NAME: <b>{state?.item?.employee[0]?.bank_name || "_________"}</b></p>
                  </div>
                  <div className="col text-end">
                    <p>EMPLOYEE NAME: <b className="text-uppercase">{state?.item?.employee[0]?.name || "_________"}</b></p>
                    <p>MONTH & YEAR: <b className="text-uppercase">{`${state?.item?.salary_month || "________"} ${state?.item?.salary_year || "________"}`}</b></p>
                    <p>BRANCH: <b className="text-uppercase">{state?.item?.employee[0]?.branch_name || "________"}</b></p>
                  </div>
                </div>
                <table className="table mt-3">
                  <thead>
                    <tr>
                      <th>EARNING</th>
                      <th>AMOUNT</th>
                      <th>DEDUCTIONS</th>
                      <th>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Basic Salary</td>
                      <td>{state?.item?.employee[0]?.salary || "________"}  Rs/-</td>
                      <td>Bonus</td>
                      <td>{state?.item?.bonus || "0"}  Rs/-</td>
                    </tr>
                    <tr>
                      <td>A/C No: </td>
                      <td>{state?.item?.employee[0]?.account_number || "_________"}  </td>
                      <td>Deduction</td>
                      <td>{state?.item?.employee[0]?.deduction_amount || "0"}  Rs/-</td>
                    </tr>
                    <tr>
                      <td>IFSC Code: </td>
                      <td>{state?.item?.employee[0]?.ifsc_code || "_________"}</td>
                      <td>Leave</td>
                      <td>{state?.item?.leave_amount || "0"}  Rs/-</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2"><b>Net Salary</b></td>
                      <td colSpan="2"><b>{state?.item?.net_payable_salary ||"_________"}  Rs/-</b></td>
                    </tr>
                  </tfoot>
                </table>
                <div className="row mt-3">
                  <div className="col text-start mt-5">
                    <p>Branch Manager: __________</p>
                  </div>
              <div className="col text-center mt-5">
              {"               "}<p>Director: _______________________</p>
                  </div>
                </div>
              </div>
              <button onClick={() => handlePrintReceipt()}>Print</button>
            </div>
          </div>
        </div>
      </div>


      <style>
        {`
          @media print {
            .print-content {
              background-color: white;
              padding: 40px;
              height: 100vh;
            }
          }
        `}
      </style>
    </Layout>
  );
}

export default SalarySlip;