import React, { useEffect, useState } from "react";
import Mobilemenu from "../components/Mobilemenu";
import Desktopmenu from "../components/Desktopmenu";
import { Breadcrumb } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dashboardimg from "../img/dashboard-img.jpg"
import MenuTop from "../components/MenuTop";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";

const Dashboard2 = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const {role} = useSelector((state) => state.auth);
  const [dashCount, setDashCount]=useState({});
useEffect(() => {
  const getDashboardCount=async()=>{
    try {
      const response = await axios.get(`${BASE_URL}admin/dashboardcount`,{
        headers: {
          "x-access-token": localStorage.getItem("token"),
          version: "1.0.0",
        },
      });
      setDashCount(response.data.data);
    } catch (error) {
      console.error('Error getting dashboard count', error);
    }
  }
  getDashboardCount();
console.log('dashCount',dashCount);
},[]);
  return (
 <Layout>
  <div className="element-wrapper">
          <div className="element-box " style={{ height:"100vh" }}>
          <div className="content-i">
            <div className="content-box p-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="element-wrapper pb-0">
                   
                    <h5 className="element-heade m-0 mb-2 ps-4"> Dashboard</h5>
                    <div className="element-content" >
                      {/* <img src={dashboardimg} className="img-fluid" style={{ width:"100%", height:"100%" }}/> */}
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Agree Loans</div>
                            <div className="value">{dashCount.loanAgreeCount}</div>
                            {/* <div className="trending trending-up">
                            
                              <i className="os-icon os-icon-arrow-up2" />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Pending Loans</div>
                            <div className="value">{dashCount.loanPendingCount}</div>
                            {/* <div className="trending trending-down-basic">
                           
                              <i className="os-icon os-icon-arrow-2-down" />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Running Loans</div>
                            <div className="value">{dashCount.loanRunningCount}</div>
                            {/* <div className="trending trending-down-basic">
                          
                              <i className="os-icon os-icon-graph-down" />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Employees</div>
                            <div className="value">{dashCount.employeeCount}</div>
                            {/* <div className="trending trending-down-basic">
                          
                              <i className="os-icon os-icon-graph-down" />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Finance Companies</div>
                            <div className="value">{dashCount.financeCompanyCount}</div>
                            {/* <div className="trending trending-down-basic">
                          
                              <i className="os-icon os-icon-graph-down" />
                            </div> */}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="element-box el-tablo">
                            <div className="label">Insurance Companies</div>
                            <div className="value">{dashCount.insuranceCompanyCount}</div>
                            {/* <div className="trending trending-down-basic">
                          
                              <i className="os-icon os-icon-graph-down" />
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                      <div className="p-3">
                        <span className="text-primary fw-bold">Loan</span> : <span>To add Loan details > Loan Finance Menu > Apply Loan > add details </span>
                      </div>
                      <div className="p-3">
                        <span className="text-primary fw-bold">RTO</span> : <span>To add RTO details > RTO Menu > Apply RC > add details </span>
                      </div>
                      <div className="p-3">
                        <span className="text-primary fw-bold">Insurance</span> : <span>To add Insurance details > Insurance Menu > Add Insurance > add details </span>
                      </div>
                      <div className="p-3">
                        <span className="text-primary fw-bold">Employee</span> : <span>To add Employee  > Employee Menu > Add Employee > add details </span>
                      </div>

                      <div className="p-3">
                        <span className="text-primary fw-bold">Company</span> : <span>To add Company  > General Setting Menu > I/F Company Add > add details </span>
                      </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            {/* <Neworders/> */}
            
          
          
            </div>
        
   
          </div>
          </div>
        </div>


 
 </Layout>
        
       
  );
};

export default Dashboard2;
