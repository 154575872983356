import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import LoanStatusListing from "../../components/Loan/LoanStatusList";



function AgreeLoanListing() {
 
  return (
  <LoanStatusListing status="Agree Leed"/>
  );
}

export default AgreeLoanListing;
