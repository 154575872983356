import React from 'react'

function PendingDocuments({handleInputChange,
    values,
    setActiveTab,
    SaveLoan,
    loanId,
    loading,
    LoanByLoanId}
) {

    let labels=[{label:"Cheque",name:'cheque'}, {label:"Bank Passbook",name:'bankPassbook'},{label:"Vehical Original RC Record",name:"vehicalOriginalRc"}, {label:"New Bank Account",name:"newBankAccount"},{label:"Bank ATM",name:'bankATM'},{label:"Aadhar Card Linked Mobile Number",name:"aadharCardLinkedMobileNumber"},{label:"Bank Account Linked Mobile Number",name:"bankAccountLinkedMobileNumber"},{label:"Form 34 Signature",name:"form34Signature"},{label:"Signature Verification",name:"signatureVerification"} ]
  return (
    <>
        <fieldset>
        <legend>
          <span>Customer Pending Documents</span>
        </legend>
        <div className="row">
  
                    <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Cheque </label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="cheque" value={values.cheque}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                  
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Bank Passbook </label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="bankPassbook" value={values.bankPassbook}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                  
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Vehical Original RC Record </label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="vehicalOriginalRc" value={values.vehicalOriginalRc}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                    
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">New Bank Account </label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="newBankAccount" value={values.newBankAccount}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Bank ATM</label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="bankATM" value={values.bankATM}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Aadhar Card Linked Mobile Number</label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="aadharCardLinkedMobileNumber" value={values.aadharCardLinkedMobileNumber}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Bank Account Linked Mobile Number</label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="bankAccountLinkedMobileNumber" value={values.bankAccountLinkedMobileNumber}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Form 34 Signature</label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="form34Signature" value={values.form34Signature}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>


                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="">Signature Verification</label>
                      <select  className="form-control " onChange={(e) => handleInputChange(e)}  name="signatureVerification" value={values.signatureVerification}>
                      <option value={""}>--select--</option>
        
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                  </div>
        </div>

        <div className="form-buttons-w text-right">
           
            <span className=" text-right">
              <button
                className="btn btn-primary text-white"
                onClick={() => SaveLoan("fourthStep")}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <span>Save</span>
                )}
              </button>
            </span>
            &nbsp;&nbsp;
          </div>
      </fieldset>
    </>
  )
}

export default PendingDocuments