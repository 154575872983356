import axios from "axios";
import React, { useEffect, useState } from "react";
import { getDocName } from "../../services/Loan/document";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function EmployeeDoc({
  handleInputChange,
  SaveEmployee,
  setActiveTab,
  handleClick,
  values,
  empId
}) {
  const [documents, setDocuments] = useState([]);
  const navigate=useNavigate();
  const [docFile, setDocFile] = useState("");
  const [docName, setDocName] = useState("");
  const [docId, setDocId] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [allDocName, setAllDocName] = useState([]);
  const BASE_URL= process.env.REACT_APP_BASE_URL;

  const handleDocChange = (e) => {
    setDocFile(e.target.files);
    const files = Array.from(e.target.files);
    setFileNames(files.map((file) => file.name));
  };

  const addEmployeeDocs = () => {
    if (!docName) {
      toast.error("please select doc name");
    }
    const token = localStorage.getItem("token");
    const fd = new FormData();
    fd.append("doc_file", docFile[0]);
    fd.append("doc_name", docName);
    fd.append("empId", empId);
    let api = "addEmployeeDoc";
    if (docId != "") {
      fd.append("id", docId);
      api = "updateEmployeeDocs";
    }
    axios
      .post(`${BASE_URL}admin/${api}`, fd, {
        headers: {
          "x-access-token": `${token}`,
          "Content-Type": "multipart/form-data",
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getEmployeeDoc();
          setDocName("");
          setDocId("");
        } else {
          if (response.data.error_code == 461) {
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllDocName = async () => {
    const data = await getDocName();
    if (data.data.success) {
      setAllDocName(data.data.data);
    }
  };
  const deleteDocuments = async (id) => {
    axios
      .delete(`${BASE_URL}admin/deleteEmployeeDoc/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
          version: "1.0.0",
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          getEmployeeDoc();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployeeDoc = async () => {
    if(empId!=""){
      axios
      .post(
        `${BASE_URL}admin/getAllEmployeeDocs`,
        { empId: empId },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            version: "1.0.0",
            "Content-Type": "Application/json"
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setDocuments(response.data.data);
        } else {
          setDocuments([]);
        }
      });
    }

  };
  const updateLoanDoc = (item) => {
    setDocId(item._id);
    setDocName(item.doc_name);
    setDocFile(item.doc_file);
    setFileNames(item.doc_file);
  };

  useEffect(()=>{
   console.log(empId,1111)
    getAllDocName();
  },[])
  useEffect(()=>{
    getEmployeeDoc();
  },[empId])
  return (
    <>
      <fieldset>
        <legend>
          <span>Add Documents</span>
        </legend>
        <div className="row">

          <div className="col-sm-6">
            <div class="form-group">
              <label htmlFor=""> Doc Name</label>
              <select
                className="form-control text-capitalize"
                onChange={(e) => setDocName(e.target.value)}
                name="docName"
                value={docName}
              >
                <option selected="true" value={""}>
                  -select-
                </option>
                {allDocName?.length &&
                  allDocName.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div className="container">
                <label
                  htmlFor="et_pb_contact_brand_file_request_0 "
                  className="et_pb_contact_form_label pb-0"
                  // onDragOver={handleDragOver}
                  // onDragLeave={handleDragLeave}
                  // onDrop={handleDrop}
                >
                  Upload File
                </label>
                <input
                  type="file"
                  // id="fileUpload"
                  // id="et_pb_contact_brand_file_request_0"
                  className="file-upload"
                  onChange={handleDocChange}
                />

                <div className="file_names">
                  {fileNames ? fileNames : fileNames[0]}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-buttons-w text-right">
          <span className=" text-right">
            {/* <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("secondStep")}
                            >
                              Back
                            </button> */}
          </span>
          &nbsp;&nbsp;{" "}
          <span className=" text-right">
            <button
              className="btn btn-primary text-white"
              onClick={() => addEmployeeDocs()}
            >
              Save
            </button>
          </span>
          &nbsp;&nbsp;
        </div>

        <div className="table-responsive">
          <table className="table table-lightborder">
            <thead>
              <tr>
                <th>Doc Name</th>
                <th>Doc File</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {documents?.length > 0 &&
                documents.map((item, index) => (
                  <tr key={item._id}>
                    <td>{item.doc_name}</td>
                    <td>
                      <a
                        href={item.doc_file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    </td>

                    <td>
                      <button className="border-0 bg-white">
                        <i
                          className="os-icon os-icon-pencil-2"
                          onClick={() => updateLoanDoc(item)}
                        />
                      </button>

                      <button
                        className="text-danger border-0 bg-white ps-2"
                        onClick={() => deleteDocuments(item._id)}
                      >
                        <i className="os-icon os-icon-database-remove" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </fieldset>

      <div className="form-buttons-w text-right">
        <span className=" text-right">
          <button
            className="btn btn-primary text-white"
            onClick={() => setActiveTab("secondStep")}
          >
            Back
          </button>
        </span>
        &nbsp;&nbsp;{" "}
        <span className=" text-right">
          <button
            className="btn btn-primary text-white"
            onClick={() => SaveEmployee("final")}
          >
            Save
          </button>
        </span>
        &nbsp;&nbsp;
      </div>
    </>
  );
}

export default EmployeeDoc;
