import React, { useEffect, useState } from 'react'
import {getAllRTOAgent} from "../../services/RTO/Agent"
import { useNavigate } from 'react-router-dom';
function RTOExpences({
    SaveRTO,
    values,
    handleInputChange,
    setActiveTab
}) {
    const navigate=useNavigate();
    const [allRTOAgent, setAllRTOAgent] = useState([]);
    const getAllAgents = async () => {
        const data = await getAllRTOAgent();
        if (data.data.success) {
            setAllRTOAgent(data.data.data);
        } else {
            setAllRTOAgent([]);
        }
      };

      useEffect(() => {
        getAllAgents();
      }, []);
  return (
    <>
      {values.totalIncome > 0 ? (
                        <div className="fs-3">
                          <b> RTO Work Fees : <i className="fa fa-inr"></i>{values.totalIncome}</b>
                        </div>
                      ) : (
                        <div className="fs-3">
                          <b>  RTO Work Fees : <i className="fa fa-inr"></i> 0</b>
                        </div>
                      )}

                      <fieldset className="mt-4">
                        <legend>
                          <span> RTO Expences</span>
                        </legend>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">T.O Fees</label>
                              <input
                                className="form-control"
                                placeholder="Enter T.O Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="TO_fees"
                                value={values.TO_fees}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="">T.O + Address Change Fees </label>
                              <input
                                className="form-control"
                                placeholder="Enter T.O + Address Change Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="TO_AddressChange_Fees"
                                value={values.TO_AddressChange_Fees}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label for="">C/O Fees</label>
                              <input
                                className="form-control"
                                placeholder="Enter C.O Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="CO_fees"
                                value={values.CO_fees}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">HSRP Fees</label>
                              <input
                                className="form-control"
                                placeholder="Enter HSRP Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="HSRP_Fees"
                                value={values.HSRP_Fees}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">DRC Fees</label>
                              <input
                                className="form-control"
                                placeholder="Enter DRC Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="DRC_Fees"
                                value={values.DRC_Fees}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Tax</label>
                              <input
                                className="form-control"
                                placeholder="Enter Tax"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="Tax"
                                value={values.Tax}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Open Bank Account</label>
                              <input
                                className="form-control"
                                placeholder="Enter Open Bank Account"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="Open_BankAccount"
                                value={values.Open_BankAccount}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Stamp Fees</label>
                              <input
                                className="form-control"
                                placeholder="Stamp Fees"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="RTO_Stamp_Fees"
                                value={values.RTO_Stamp_Fees}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Form 34</label>
                              <input
                                className="form-control"
                                placeholder="Enter amount for form 34"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="form_34"
                                value={values.form_34}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Form 35</label>
                              <input
                                className="form-control"
                                placeholder="Enter amount for form 35"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="form_35"
                                value={values.form_35}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Vehical Renewal</label>
                              <input
                                className="form-control"
                                placeholder="Enter amount for vehical renewal"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="vehical_renewal"
                                value={values.vehical_renewal}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Form 31 (DEATH)</label>
                              <input
                                className="form-control"
                                placeholder="Enter amount for form 31 (DEATH)"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="form_31"
                                value={values.form_31}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="">Other Expence</label>
                              <input
                                className="form-control"
                                placeholder="Other Expence"
                                type="number"
                                onChange={(e) => handleInputChange(e)}
                                name="otherExpences"
                                value={values.otherExpences}
                              />
                            </div>
                          </div>

                          {/* <hr/> */}
                          <div className="col-sm-12 ">
                            <div
                              className="d-flex justify-content-between"
                              style={{
                                backgroundColor: "#e4e4e4",
                                padding: "10px",
                              }}
                            >
                              <div>
                                <b>Total RTO Deduction Charges</b>
                              </div>
                              {values.name ? (
                                <div>
                                  <b>
                                    <i className="fa fa-inr"></i>{" "}
                                    {values.rtoDeductionCharges}
                                  </b>
                                </div>
                              ) : (
                                <div>0</div>
                              )}
                            </div>
                          </div>
                        </div>

                        <fieldset className="mt-4">
                          <legend>
                            <span> RTO Service Charge To</span>
                          </legend>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">RTO Agent</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => handleInputChange(e)}
                                  name="agentId"
                                  value={values.agentId}
                                >
                                  <option value={""}>--select--</option>
                                  {allRTOAgent.length > 0 &&
                                    allRTOAgent.map((item) => (
                                      <option
                                        key={item._id}
                                        value={item._id}
                                        className="text-capitalize"
                                      >
                                        {item.name}
                                        <br /> ({"+91 " + item.mobile_no})
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>

                            {/* <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">RTO Agent Charge</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter RTO Service Charges"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="rtoServiceCharge"
                                  value={values.rtoServiceCharge}
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">Other Charges</label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Other RTO Service Charges"
                                  type="number"
                                  onChange={(e) => handleInputChange(e)}
                                  name="rtoServiceOtherCharge"
                                  value={values.rtoServiceOtherCharge}
                                />
                              </div>
                            </div> */}

                          </div>
                        </fieldset>

                        {/* <div className="col-sm-12 p-0">
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              backgroundColor: "#e4e4e4",
                              padding: "10px",
                            }}
                          >
                            <div>
                              <b>Total RTO Expences</b>
                            </div>
                            {values.name ? (
                              <div>
                                <b>
                                  <i className="fa fa-inr"></i>{" "}
                                  {values.totalRtoExpence}
                                </b>
                              </div>
                            ) : (
                              <div>0</div>
                            )}
                          </div>
                        </div> */}

                        <div className="form-buttons-w text-right">
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => setActiveTab("firstStep")}
                            >
                              Back
                            </button>
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span className=" text-right">
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => {SaveRTO("secondStep")
                                navigate("/rto-listing")
                              }}
                            >
                              Save
                            </button>
                          </span>
                          &nbsp;&nbsp;
                        </div>
                      </fieldset>
    </>
  )
}

export default RTOExpences